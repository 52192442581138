<template>
  <v-form ref="formElem">
    <v-card>
      <translation-locales />

      <v-card-text>
        <div>
          Notes:<br><br>
          Version number: provided by App developer after deployment<br><br>
          Enforce Update: app user must update before use the app, when it set to YES.'<br><br>
          Title: summary of update.<br>
          e.g.:'The payment module added Alipay.', <br><br>
          Content: update logs, deployment details.<br>
          e.g.:'Alipay is now available to user. Added redirect link when payment failed.'<br><br>
          Effective Date: when the deployment is effective
        </div>

        <v-select
          v-model="form.os"
          class="mt-5"
          label="Platform"
          outlined
          item-text="name"
          item-value="value"
          :items="osOptions"
          :rules="[(v) => !!v || 'Platform is required']"
        />

        <v-text-field
          v-model="form.version"
          label="Version"
          outlined
          :rules="[required]"
        />
        <v-text-field
          v-model="form.app_link"
          label="App Link"
          hint="aos=https://play.google.com/store/apps/details?id=com.mixcarehealth.wellness&pli=1"
          outlined
          :rules="[required]"
        />

        <translatable-input
          v-model="form.title"
          label="Title"
        />
        <translatable-input
          v-model="form.content"
          label="Content"
        />

        <div>Effective Date:</div>
        <v-date-picker
          v-model="form.effective_date"
          label="Effective Date"
          outlined
          class="mb-6"
        />

        <v-row>
          <v-col
            cols="6"
            class="d-flex align-center"
          >
            <div class="">
              Enforce Update:
            </div>
          </v-col>
          <v-col cols="6">
            <v-switch
              v-model="form.enforce_update"
              color="primary"
              :label="form.enforce_update ? 'Yes' : 'No'"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { fetchModules } from '@/api/module/distModule'
import { TranslatableInput, TranslationLocales } from '@/components'
import { useTranslatable } from '@/composables'
import { required } from '@core/utils/validation'
import { ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export default {
  components: {
    TranslatableInput,
    TranslationLocales,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const parseResource = () => {
      if (props.resource) props.resource.effective_date = dayjs(props.resource.effective_date).format('YYYY-MM-DD')

      return props.resource
    }

    const formatData = data => {
      data.effective_date = dayjs(data.effective_date).utc().local().format()

      return data
    }

    const parsedResource = parseResource()

    // Get value from resource prop, use initValue if not exist
    const field = (name, initValue) => ({
      [name]: props.resource ? props.resource[name] : initValue,
    })

    const rules = props.resource ? props.resource.rules : false

    const initialForm = {
      ...field('version', null),
      ...field('enforce_update', false),
      ...field('title', useTranslatable(null)),
      ...field('content', useTranslatable(null)),
      ...field('effective_date', dayjs().utc().local().format('YYYY-MM-DD')),
      ...field('os', 'ios'),
      ...field('app_link', 'https://apps.apple.com/hk/app/mixcare-health-wellness/id6450120842'),
    }

    const form = ref({
      ...initialForm,
    })
    const formElem = ref(null)

    const osOptions = ref([
      {
        name: 'Android',
        value: 'aos',
      },
      {
        name: 'iOS',
        value: 'ios',
      },
    ])

    const validate = () => {
      if (formElem.value.validate()) emit('submit', formatData(form.value))
    }

    const modules = ref([])

    const loadModules = async () => {
      try {
        const {
          status,
          data: {
            data: { records },
          },
        } = await fetchModules()

        if (status === 200) {
          modules.value = records.map(record => ({
            title: record.name.en,
            value: record.id,
          }))
        }
      } catch (error) {
        console.log(error)
      }
    }

    loadModules()

    return {
      form,
      formElem,
      validate,
      required,

      osOptions,
      modules,
    }
  },
}
</script>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formElem"
  }, [_c('v-card', [_c('translation-locales'), _c('v-card-text', [_c('div', [_vm._v(" Notes:"), _c('br'), _c('br'), _vm._v(" Version number: provided by App developer after deployment"), _c('br'), _c('br'), _vm._v(" Enforce Update: app user must update before use the app, when it set to YES.'"), _c('br'), _c('br'), _vm._v(" Title: summary of update."), _c('br'), _vm._v(" e.g.:'The payment module added Alipay.', "), _c('br'), _c('br'), _vm._v(" Content: update logs, deployment details."), _c('br'), _vm._v(" e.g.:'Alipay is now available to user. Added redirect link when payment failed.'"), _c('br'), _c('br'), _vm._v(" Effective Date: when the deployment is effective ")]), _c('v-select', {
    staticClass: "mt-5",
    attrs: {
      "label": "Platform",
      "outlined": "",
      "item-text": "name",
      "item-value": "value",
      "items": _vm.osOptions,
      "rules": [function (v) {
        return !!v || 'Platform is required';
      }]
    },
    model: {
      value: _vm.form.os,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "os", $$v);
      },
      expression: "form.os"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Version",
      "outlined": "",
      "rules": [_vm.required]
    },
    model: {
      value: _vm.form.version,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "version", $$v);
      },
      expression: "form.version"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "App Link",
      "hint": "aos=https://play.google.com/store/apps/details?id=com.mixcarehealth.wellness&pli=1",
      "outlined": "",
      "rules": [_vm.required]
    },
    model: {
      value: _vm.form.app_link,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "app_link", $$v);
      },
      expression: "form.app_link"
    }
  }), _c('translatable-input', {
    attrs: {
      "label": "Title"
    },
    model: {
      value: _vm.form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  }), _c('translatable-input', {
    attrs: {
      "label": "Content"
    },
    model: {
      value: _vm.form.content,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  }), _c('div', [_vm._v("Effective Date:")]), _c('v-date-picker', {
    staticClass: "mb-6",
    attrs: {
      "label": "Effective Date",
      "outlined": ""
    },
    model: {
      value: _vm.form.effective_date,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "effective_date", $$v);
      },
      expression: "form.effective_date"
    }
  }), _c('v-row', [_c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {}, [_vm._v(" Enforce Update: ")])]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-switch', {
    attrs: {
      "color": "primary",
      "label": _vm.form.enforce_update ? 'Yes' : 'No'
    },
    model: {
      value: _vm.form.enforce_update,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "enforce_update", $$v);
      },
      expression: "form.enforce_update"
    }
  })], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(" Submit ")])], 1), _vm._l(_vm.errors, function (msg) {
    return _c('v-alert', {
      key: msg,
      attrs: {
        "color": "warning"
      }
    }, [_vm._v(" " + _vm._s(msg) + " ")]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }